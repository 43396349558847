
































import {Component, Mixins, Watch} from 'vue-property-decorator'
import AddButton from '@/general/components/common/AddButton.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import {Header} from '@/general/models/Vuetify'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import ShipmentService from "@/modules/shipments/services/ShipmentService";
import {
  ListShipments_ListShipments_items,
  ListShipments_ListShipments_items_consignments_actors_pickup_status,
  ListShipments_ListShipments_items_consignments_actors_pickup_status_documents
} from "@/generated/ListShipments";
import Popup from '@/general/components/common/Popup.vue'
import BaseSelect from '@/general/components/general/BaseSelect.vue'
import {DocumentType, ImportConversion} from "@/generated/globalTypes";

@Component({
  components: {
    BaseSelect,
    Popup,
    BaseDataTable,
    InfoCard,
    AddButton
  }
})
export default class Overview extends Mixins(ShipmentService) {
  shipments: ListShipments_ListShipments_items[] = []
  finished: boolean = Boolean(JSON.parse((this.$route.query.finished as string) || 'false'))
  show = true
  conversions = Object.values(ImportConversion)

  createPopup = false
  file: string | null = null
  conversion: ImportConversion | null = null

  closeCreate() {
    // @ts-ignore
    this.$refs.fileElement.value = null
    this.createPopup = false
    this.file = null
    this.conversion = null
  }

  get service() {
    if (this.finished) {
      return this.getFinishedShipmentOverview
    } else {
      return this.getShipmentOverview
    }
  }

  created() {
    this.subscribe()
  }

  handleFileInput(evt: any) {
    const file = evt.target.files[0]
    const reader = new FileReader();
    const that = this
    reader.onload = () => {
      that.file = (reader.result as string).split(',')[1]
    }
    reader.readAsDataURL(file);
  }

  createFromConversion() {
    this.graphqlCreate({ conversionType: this.conversion!, data: this.file! }).then(result => this.closeCreate())
  }

  get processingHeader() {
    return [new Header('Processing', 'processing', false)]
  }

  get headers() {
    return [
      new Header('#', 'index', false),
      new Header('ID', 'identifier'),
      new Header('shipment.fields.createdAt', 'created'),
      new Header('shipment.fields.updatedAt', 'updated'),
      new Header('e-CMR', 'ecmr', false),
      new Header('shipment.lastStatus', 'status', false),
      new Header('common.attachments', 'attachments', false),
      new Header('common.location', 'location', false)
    ]
  }

  getDocuments(shipment: ListShipments_ListShipments_items, retrieveECMR: boolean): ListShipments_ListShipments_items_consignments_actors_pickup_status_documents[] {
    let documents = shipment.consignments.flatMap(consignment => consignment.actors.flatMap(actor => [actor.pickup.status, actor.delivery.status, ...actor.progresses?.map(progress => progress.status).filter(status => status)!!]).filter(status => status)).flatMap(statuses => {
      return statuses?.documents ? statuses.documents : []
    }).filter(document => retrieveECMR ? document.type === DocumentType.eCMR : document.type !== DocumentType.eCMR)

    return [...shipment.consignments.flatMap(consignments => consignments.documents), ...documents].filter(document => document) as ListShipments_ListShipments_items_consignments_actors_pickup_status_documents[]
  }

  getLastStatus(shipment: ListShipments_ListShipments_items): ListShipments_ListShipments_items_consignments_actors_pickup_status[] {
    return shipment.consignments.flatMap(consignment => consignment.actors.flatMap(actor => [actor.pickup.status, actor.delivery.status, ...actor.progresses?.map(progress => progress.status)!!]).filter(status => status)).sort((status1, status2) => {
      return new Date(status2!!.created).getTime() - new Date(status1!!.created).getTime()
    }) as ListShipments_ListShipments_items_consignments_actors_pickup_status[]
  }

  rerender() {
    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }

  @Watch('$route.query.finished')
  watchFinished() {
    this.finished = Boolean(JSON.parse((this.$route.query.finished as string) || 'false'))
    this.rerender()
  }

  handleSubscription(id: number) {
    this.rerender()
  }

  destroyed() {
    this.$websocket.eventEmitter.removeListener('SHIPMENT', this.handleSubscription)
  }

  subscribe() {
    this.$websocket.eventEmitter.on('SHIPMENT', this.handleSubscription)
  }
}
